<template>
  <div class="health-punch">
    <div class="punch">
      <div class="punch_item" :class="{'punch_danger': health == 1}">
        <div class="item_btn">打卡成功</div>
        <div class="time">打卡日期：{{nowDate}}</div>
      </div>
    </div>
    <!-- <div class="need-report" v-if="needReport">
      今日需要提交核酸检测报告
      <div class="report-btn" @click="toReport">立即提交</div>
    </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    var date = new Date()
    return {
      health: '',
      needReport: false,
      nowDate: date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '-' + date.getDate()
    }
  },
  computed: {
    ...mapState(['houseId']),
  },
  created () {
    this.health = this.$route.query.health
    this.$http({
      url: this.$http.adornUrl('/wxapp/disease/isolation/res/report/isNeed'),
      method: 'get',
      params: this.$http.adornParams({
        userId: this.$route.query.userId,
        houseId: this.houseId
      })
    }).then(({data}) => {
      if (data && data.code === 0) {
        this.needReport = data.need && !data.isReport
      }
    })
  },
  // mounted () {
  //   if (window.history && window.history.pushState) {
  //     history.pushState(null, null, document.URL)
  //     window.addEventListener('popstate', this.refreshFn, false)
  //   }
  // },
  // destroyed(){
  //   window.removeEventListener('popstate', this.refreshFn, false)
  // },
  // methods: {
  //   refreshFn () {
  //     wx.miniProgram.redirectTo({
  //       url: '/pages/common/folk/info?houseId=' + this.$houseId
  //     })
  //   },
  //   toReport () {
  //     wx.miniProgram.redirectTo({
  //       url: '/pages/common/folk/reportAcid?userId=' + this.$id + '&houseId=' + this.$houseId
  //     })
  //   }
  // },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background-color:transparent')
  }
}
</script>
<style scoped lang="scss">
.health-punch {
  margin: 25px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
}
.punch {
  .punch_item {
    color: #fff;
    margin: 40px auto;
    width: 400px;
    height: 400px;
    background-image: url('~@/assets/img/health-punch-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .item_btn {
      font-size: 30px;
      line-height: 50px;
    }
    .item_date {
      font-size: 26px;
      line-height: 50px;
    }
    .time {
      font-size: 28px;
      width: 600px;
      text-align: center;
      color: #999;
      position: absolute;
      bottom: 20px;
    }
  }
  .punch_danger {
    background-image: url('~@/assets/img/health-punch-danger.png');
  }
}
.need-report {
  font-size: 28px;
  color: #333;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: -128px;
  .report-btn {
    margin-top: 24px;
    color: #4581F8;
  }
}
</style>
